<template>
  <div id="calender"></div>
  <a v-if="showLink" class="link" href="./calender.php">Till kalender</a>
</template>

<script>
import { onMounted, ref } from "vue";
import { token } from "@/utilities/token";
import { searchOnClickFunction } from "@/utilities/SearchHelper";

export default {
  props: {
    list: {
      type: Array,
      default: null,
    },
    instrument: {
      type: Array,
      defualt: null,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
    maxrows: {
      type: Number,
    },
    divId: {
      type: String,
      default: "calender",
    },
    pagination: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const buildWidgets = () => {
      MillistreamWidgetSettings.language = "sv";
      MillistreamWidgetSettings.token = token;
      new Milli_Calendar({
        target: document.getElementById(props.divId),
        instrument: props.instrument,
        list: props.list,
        multiline: false,
        maxrows: props.maxrows,
        noheader: true,
        fields: props.fields,
        dividends: false,
        pagination: props.pagination,
        // href: "detailed.html?",
        underlyingid: true,
        link_field: [
          "insref",
          "name",
          "symbol",
          "marketplace",
          "instrumenttype",
        ],
        onreadyCallback: function () {},
        onrowclick: (e) => searchOnClickFunction(e),
      });
    };
    onMounted(() => {
      buildWidgets();
    });
    return {};
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
